import {
  useGetCall,
  useLazyGetCall,
  useLazyPostCall,
} from 'common/api/useApiCall';
import { TICKETS_BASE_URL, URLS } from 'constants/URL';
import { useState, useEffect, useMemo, useContext } from 'react';
import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import { useCreateTicket } from 'customers/tenants-detailed-view/hooks/useCreateTicket';
import { useUpdateTicketStatus } from 'customers/tenants-detailed-view/hooks/useUpdateTicketStatus';
import { MODAL_TYPE } from './TicketsTable';
import { adaptTicketsResponse } from './adaptTicketsResponse';
import { useNavigate } from 'react-router';
import { TICKETS_PER_PAGE } from 'constants/appConstants';
import { adaptTicketAgentsResponse } from 'customers/ticket-detailed-view/ticketAdapter';
import { CommonDataContext } from 'common/Contexts/CommonData';
import { useDataAndCount } from 'common/Hooks/useDataAndCount';
import { removeResolvedAndClosedFromDefaultFilter } from './helpers';
import { createParams } from 'helpers/createParams';
import { exportResponseAdapter } from 'customers/tenants-list-view/lib/components/ExportData/exportResponseAdapter';
import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';

type TicketsProps = {
  tenant?: TenantType;
};

export const useTickets = (props: TicketsProps) => {
  const { tenant } = props;
  const { freshdeskStatuses, getStatusUsingValue } =
    useContext(CommonDataContext);
  const { addNotification } = useContext(NotificationContext);

  const defaultFilter = useMemo(() => {
    return {
      status: removeResolvedAndClosedFromDefaultFilter(freshdeskStatuses),
    };
  }, [freshdeskStatuses]);

  const priority = new URLSearchParams(window.location.search).get('priority');
  const [showModal, setShowModal] = useState<MODAL_TYPE | null>(null);
  const [selectedTicket, setSelectedTicket] = useState();

  const [filters, setFilters] = useState<any>(defaultFilter);
  const navigate = useNavigate();

  const { response: ticketAgents } = useGetCall(
    URLS.fetchTicketAgents,
    adaptTicketAgentsResponse,
  );

  const ticketsParams = useMemo(() => {
    if (tenant?._id) {
      return {
        'tenant-ids': tenant?._id,
        ...filters,
      };
    } else {
      return { ...filters };
    }
  }, [tenant, filters]);

  const { response: tenantIdNamesResponse, postData: getTenantsWithId } =
    useLazyGetCall(`${URLS.getTenantIdNames}with-id=true`);

  useEffect(() => {
    if (!tenant?._id) {
      getTenantsWithId();
    }
  }, [tenant]);

  const tenants = useMemo(() => {
    return tenantIdNamesResponse?.response?.length
      ? tenantIdNamesResponse?.response?.map((_t) => ({
          value: _t?._id,
          label: _t?.domain,
        }))
      : [];
  }, [tenantIdNamesResponse]);

  const {
    count: ticketsCount,
    pageCount: ticketsPageCount,
    dataResponse: _tickets,
    dataIsLoading: isLoadingTickets,
    page,
    handlePaginationChange,
    fetchDataAndCount: fetchTickets,
    setPage,
  } = useDataAndCount({
    url: TICKETS_BASE_URL,
    dataResponseAdapter: adaptTicketsResponse,
    params: ticketsParams,
    itemsPerPage: TICKETS_PER_PAGE,
  });

  const {
    response: exportResponse,
    responseStatus: exportResponseStatus,
    isLoading: exportIsLoading,
    postData: exportData,
  } = useLazyPostCall(
    `${URLS.exportTickets}${createParams(ticketsParams)}`,
    exportResponseAdapter,
  );

  useEffect(() => {
    if (exportResponse?.message) {
      addNotification({
        severity: exportResponseStatus === 200 ? 'success' : 'error',
        message: `${exportResponse?.message}`,
      });
    }
  }, [exportResponse]);

  const handleExportClick = (fileType: string) => {
    const payload = {
      'file-type': fileType,
    };
    exportData(JSON.stringify(payload, null));
  };

  // map agents to tickets using responder_id and agentsResponse
  const tickets = useMemo(
    () =>
      _tickets.map((ticket) => {
        return {
          ...ticket,
          agent: ticketAgents[ticket.responder_id]?.contact?.name || '',
        };
      }),
    [_tickets, ticketAgents],
  );

  useEffect(() => {
    if (priority) {
      setFilters({ ...defaultFilter, priority: parseInt(priority) });
    } else {
      setFilters(defaultFilter);
    }
  }, [priority]);

  const handleModalClose = () => {
    resetTicketState();
    setSelectedTicket(null);
    setShowModal(null);
  };

  const handleOnSuccess = () => {
    resetTicketState();
    setSelectedTicket(null);
    setShowModal(null);
    fetchTickets();
  };

  const handleFilterChange = (filterKey, val) => {
    setPage(1);
    setFilters((prev) => ({ ...prev, [filterKey]: val }));
  };

  const handleResetFilters = () => {
    setPage(1);
    setFilters(defaultFilter);
  };

  const handleRowClick = (params) => {
    const { row: data } = params;
    const { id } = data;
    navigate(`/ticket/${id}`);
  };

  const {
    createTicket,
    resetState: resetTicketState,
    ...createTicketProps
  } = useCreateTicket({
    tenant,
    onSuccess: handleOnSuccess,
  });

  const { updateTicketStatus, ...updateTicketStatusProps } =
    useUpdateTicketStatus({
      ticket: selectedTicket,
      onSuccess: handleOnSuccess,
    });

  return {
    tickets,
    isLoadingTickets,
    showModal,
    selectedTicket,
    ticketAgents,
    setShowModal,
    updateTicketStatus,
    updateTicketStatusProps,
    createTicket,
    createTicketProps,
    handleFilterChange,
    handleResetFilters,
    handleModalClose,
    setSelectedTicket,
    handleOnSuccess,
    filters,
    handleRowClick,
    ticketsCount,
    ticketsPageCount,
    page,
    handlePaginationChange,
    freshdeskStatuses,
    getStatusUsingValue,
    tenants,
    exportIsLoading,
    handleExportClick,
  };
};
