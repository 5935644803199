import { useLazyGetCall } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { createParams } from 'helpers/createParams';
import { useEffect, useMemo, useState } from 'react';
import { adaptMyRoleResponse } from './adaptMyRoleResponse';
import { adaptParameterProfileResponse } from './adaptParameterProfileResponse';

type ServiceSummaryProps = {
  pgSource: string;
  tenantId: string;
  entityId: string;
  parameterProfileId: string;
};

export const useServiceSummary = (props: ServiceSummaryProps) => {
  const { pgSource, tenantId, entityId, parameterProfileId } = props;
  const [expanded, setExpanded] = useState(true);
  const [computeDetails, setComputeDetails] = useState({});

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isPopoverOpen = Boolean(anchorEl);

  const getMyRoleParams = useMemo(() => {
    return {
      'tenant-domain': pgSource,
      'tenant-id': tenantId,
      'dbservice-id': entityId,
    };
  }, [pgSource, tenantId, entityId]);

  const {
    response: myRoleResponse,
    isLoading: myRoleIsLoading,
    postData: getMyRole,
  } = useLazyGetCall(
    `${URLS.getMyRole}${createParams(getMyRoleParams)}`,
    adaptMyRoleResponse,
  );

  const getParameterProfleParams = useMemo(() => {
    return {
      'tenant-domain': pgSource,
      'tenant-id': tenantId,
    };
  }, [pgSource, tenantId]);

  const {
    response: parameterProfileResponse,
    isLoading: parameterProfileIsLoading,
    postData: getParameterProfile,
  } = useLazyGetCall(
    `${URLS.getParameterProfile}${parameterProfileId}?${createParams(
      getParameterProfleParams,
    )}`,
    adaptParameterProfileResponse,
  );

  useEffect(() => {
    if (parameterProfileId?.length !== 0) {
      getParameterProfile();
      getMyRole();
    }
  }, []);

  return {
    loggedInUserRole: myRoleResponse.loggedInUserRole,
    myRoleIsLoading,
    parameterProfileDescription: parameterProfileResponse?.description,
    parameters: parameterProfileResponse?.parameters,
    parameterProfileIsLoading,
    expanded,
    setExpanded,
    computeDetails,
    setComputeDetails,
    anchorEl,
    setAnchorEl,
    handleClick,
    handleClose,
    isPopoverOpen,
  };
};
