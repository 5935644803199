import { useEffect, useMemo, useRef, useState } from 'react';
import { useStyles } from '../utils';
import STATUS from '../Status';
import moment from 'moment';
import { Button, Stack, TextField } from '@mui/material';
import { FastForward, FastRewind } from '@mui/icons-material';
import { DF_HUMAN, DF_HUMAN2 } from 'helpers/dateFormats';
import useInterval from 'helpers/useInterval';
import { URLS, headers } from 'constants/URL';
import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { Logs } from '@tessell/tessell-common-ui-library';
import AppUtil from 'tools/AppUtil';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import useDownloadLog, { DownloadFileLogs, UrlProps } from './useDownloadLog';

let LOGS_END_REACH_COUNT = 1;

const DBLogs = ({ database }: any) => {
  const classes = useStyles();
  const {
    _id: entityId,
    pgSource,
    tenantId,
    createdOn,
    status,
    instances,
    engineType,
  } = database;
  const startMin = new Date(createdOn).getTime();
  const endMax = new Date().getTime();

  const [debug, setDebug] = useState(false);
  const [currentLog, setCurrentLog] = useState(null);
  const [selectedLogs, setSelectedLogs] = useState([]);
  const [logFiles, setLogFiles] = useState([]);
  const [logText, setLogText] = useState({});
  const [scrollOffset, setScrollOffset] = useState({});
  const [isLogsLoading, setIsLogsLoading] = useState(false);
  const [isPolling, setIsPolling] = useState(true);
  const [instance, setInstance] = useState(instances?.[0]?._id || '');

  const isPollingEnabled = useMemo(() => status !== STATUS.Stopped, [status]);

  const logViewerRef = useRef();
  const {
    postData: fetchLogFiles,
    isLoading,
    response: logsData,
  } = useLazyGetCallWithURL();

  useEffect(() => {
    if (instance) {
      fetchLogFiles(
        `${URLS.fetchLogFiles}tenant-id=${tenantId}&tenant-domain=${pgSource}&dbservice-id=${entityId}&instance-id=${instance}`,
      );
    }
  }, [instance]);

  useEffect(() => {
    if (logsData?.length) {
      setLogFiles(() => logsData.map((l) => l.fileName));
      setScrollOffset(() =>
        logsData.reduce(
          (obj, l) => ({
            ...obj,
            [l.fileName]: {
              scrollOffset: 0,
              scrollOffsetToBottom: 100,
            },
          }),
          {},
        ),
      );
    }
  }, [logsData]);

  function mergeLogs(logs1 = [], logs2 = []) {
    logs1.push(...logs2);
    return logs1;
  }

  function logsDateFormatter(d) {
    return moment(d).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSSSSSSSS[Z]');
  }

  async function streamLogs(
    startTime,
    endTime,
    fl,
    forward,
    clearLogs = false,
  ) {
    const url = `${URLS.fetchDBLogs}tenant-id=${tenantId}&tenant-domain=${pgSource}&dbservice-id=${entityId}&instance-id=${instance}&file-name=${fl}&start-time=${startTime}&end-time=${endTime}&forward=false`;
    function setFileInfo(logs, metadata) {
      function handleTimestamp(d1, d2) {
        if (d1 && logs.length >= 500) {
          return logsDateFormatter(d1);
        }
        return d2;
      }

      function setText(logs) {
        if (logs.length === 0) {
          return 'No logs found. Please load previous logs';
        }
        return logs.join('\n');
      }

      const fileInfo = logText[fl] || {};
      if (metadata) {
        if (clearLogs) {
          return {
            logs,
            text: setText(logs),
            startTime: handleTimestamp(metadata.firstEventTimestamp, startTime),
            endTime,
          };
        }

        if (forward) {
          const mergedLogs = mergeLogs(fileInfo.logs, logs);
          return {
            logs: mergedLogs,
            text: setText(mergedLogs),
            startTime: fileInfo.startTime || startTime,
            endTime: handleTimestamp(metadata.lastEventTimestamp, endTime),
          };
        }
        const mergedLogs = mergeLogs(logs, fileInfo.logs);
        return {
          logs: mergedLogs,
          text: setText(mergedLogs),
          startTime: handleTimestamp(metadata.firstEventTimestamp, startTime),
          endTime: fileInfo.endTime || endTime,
        };
      }
      return fileInfo;
    }
    setIsLogsLoading(true);

    await fetch(url, {
      headers: headers(),
    })
      .then((res) => res.json())
      .then((res) => {
        if (Array.isArray(res)) {
          setLogText((prev) => ({
            ...prev,
            [fl]: setFileInfo(res[0].logs, res[0].metadata),
          }));
        }
      })
      .catch(() => {});
    setIsLogsLoading(false);
  }

  async function streamNextLogs(logsToFetch) {
    const fileInfo = logText[logsToFetch] || {};
    if (!fileInfo.endTime) {
      const endTime = logsDateFormatter(new Date().getTime());
      const startTime = logsDateFormatter(
        new Date().getTime() - 24 * 60 * 60 * 1000,
      );
      await streamLogs(startTime, endTime, logsToFetch, false);
    } else {
      const startTime = fileInfo.endTime;
      const endTime = logsDateFormatter(new Date().getTime());
      await streamLogs(startTime, endTime, logsToFetch, true);
    }
  }

  async function streamPreviousLogs(logsToFetch) {
    const fileInfo = logText[logsToFetch] || {};
    if (fileInfo.startTime) {
      const endTime = fileInfo.startTime;
      const startTime = logsDateFormatter(
        new Date(endTime).getTime() - 24 * 60 * 60 * 1000,
      );
      await streamLogs(startTime, endTime, logsToFetch, false);
    }
  }

  async function streamManualLogs(startTime, endTime, logsToFetch) {
    await streamLogs(
      logsDateFormatter(startTime),
      logsDateFormatter(endTime),
      logsToFetch,
      false,
      true,
    );
  }

  useInterval(
    () => {
      if (currentLog && isPolling && isPollingEnabled) {
        streamNextLogs(currentLog);
      }
    },
    15000,
    logFiles,
  );

  function HeaderComponent() {
    const currentOffset = scrollOffset[currentLog]?.scrollOffset || 0;
    const currentOffsetToBottom =
      scrollOffset[currentLog]?.scrollOffsetToBottom || 0;
    const showNextLogs = !currentOffsetToBottom && !isPolling;

    const handleClickTop = () => {
      if (currentOffset) {
        (logViewerRef.current as any)?.scrollToItem(0);
        setScrollOffset((prev) => ({
          ...prev,
          [currentLog]: {
            scrollOffset: 0,
            scrollOffsetToBottom: 100,
          },
        }));
      } else {
        setIsPolling(false);
        streamPreviousLogs(currentLog);
      }
    };

    const handleClickBottom = () => {
      if (showNextLogs) {
        setIsPolling(false);
        streamNextLogs(currentLog);
      } else {
        (logViewerRef.current as any).scrollToBottom();
        setScrollOffset((prev) => ({
          ...prev,
          [currentLog]: {
            scrollOffset: 100,
            scrollOffsetToBottom: 0,
          },
        }));
      }
    };

    return (
      <Stack direction="row" spacing={3}>
        <Button
          color="primary"
          startIcon={<FastRewind />}
          sx={{ alignSelf: 'self-start', width: '200px' }}
          onClick={handleClickTop}
        >
          {currentOffset ? 'Jump to top' : 'Load previous logs'}
        </Button>
        <Button
          color="primary"
          startIcon={<FastForward />}
          sx={classes.backButton}
          onClick={handleClickBottom}
        >
          {showNextLogs ? 'Load next logs' : 'Jump to bottom'}
        </Button>
      </Stack>
    );
  }

  const onScroll = ({
    scrollOffset,
    scrollOffsetToBottom,
    scrollUpdateWasRequested,
  }) => {
    if (!scrollUpdateWasRequested) {
      setScrollOffset((prev) => ({
        ...prev,
        [currentLog]: {
          scrollOffset,
          scrollOffsetToBottom,
        },
      }));
    }
  };

  const updateRange = (start, end) => {
    const startTime = start.valueOf();
    const endTime = end.valueOf();
    setIsPolling(false);
    setDebug(true);
    streamManualLogs(startTime, endTime, currentLog);
  };

  async function goLive() {
    if (debug) {
      const endTime = logsDateFormatter(new Date().getTime());
      const startTime = logsDateFormatter(
        new Date().getTime() - 24 * 60 * 60 * 1000,
      );
      await streamLogs(startTime, endTime, currentLog, false, true);
      setIsPolling(true);
      (logViewerRef.current as any)?.scrollToItem(0);
    } else {
      setIsPolling(true);
    }
  }

  const startTime = logText[currentLog]?.startTime;
  const endTime = logText[currentLog]?.endTime;

  const timeVal =
    startTime && endTime
      ? `${moment(startTime).format(DF_HUMAN)} - ${moment(endTime).format(
          DF_HUMAN,
        )}`
      : '';

  const FilteredComponent = () => (
    // @ts-ignore
    <DateRangePicker
      onCallback={updateRange}
      initialSettings={{
        timePicker: true,
        timePicker24Hour: true,
        timePickerSeconds: true,
        startDate: moment(new Date(startTime).getTime()),
        endDate: moment(new Date(endTime).getTime()),
        minDate: moment(startMin),
        maxDate: moment(endMax),
        opens: 'center',
        maxSpan: {
          days: 7,
        },
        ranges: {
          'Last 1 hour': [moment(endMax).subtract(1, 'h'), endMax],
          'Last 3 hours': [moment(endMax).subtract(3, 'h'), endMax],
          'Last 6 hours': [moment(endMax).subtract(6, 'h'), endMax],
          'Last 12 hours': [moment(endMax).subtract(12, 'h'), endMax],
          'Last 24 hours': [moment(endMax).subtract(24, 'h'), endMax],
        },
      }}
    >
      <TextField
        value={timeVal}
        size="small"
        name="subFilter"
        variant="outlined"
        color="primary"
        sx={classes.timePicker}
      />
    </DateRangePicker>
  );

  const { downloadFile, fetchLogs: downloadFetchLogs } = useDownloadLog({
    urlProps: {
      startTime,
      endTime,
      fl: currentLog,
      entityId,
      instance,
      forward: 'false',
    },
    selectedLogs,
  });

  return (
    <Logs
      logFiles={logFiles}
      isFilesLoading={isLoading}
      entities={instances?.filter((_i) => _i?.status !== 'DELETED')}
      entityLabel="Intance"
      selectedEntity={instance}
      setSelectedEntity={setInstance}
      HeaderComponent={HeaderComponent}
      FilteredComponent={FilteredComponent}
      logViewerRef={logViewerRef}
      streamLogs={streamNextLogs}
      isLogsLoading={isLogsLoading}
      currentLog={currentLog}
      setCurrentLog={setCurrentLog}
      selectedLogs={selectedLogs}
      setSelectedLogs={setSelectedLogs}
      logText={logText}
      setLogText={setLogText}
      filesHeading={`${AppUtil.toTitleCase(engineType)} Logs`}
      goLive={goLive}
      onScroll={onScroll}
      isPollingEnabled={isPollingEnabled}
      isPolling={isPolling}
      downloadFileLogText={downloadFile}
      downloadFetchLogs={downloadFetchLogs}
    />
  );
};

export default DBLogs;
